.result-container {
  text-align: center;
  padding: 20px;
  max-width: 600px;
  margin: auto;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 0px;
}

.result-container h1 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #2c3e50;
}

.result-container p {
  font-size: 20px;
  margin-bottom: 15px;
  color: #3498db;
}

.score-bar {
  width: 100%;
  height: 20px;
  background-color: #ecf0f1;
  border-radius: 10px;
  margin: 20px 0;
  overflow: hidden;
  position: relative;
}

.score-fill {
  height: 100%;
  background-color: #2ecc71;
  border-radius: 10px;
  width: 80%;
  animation: fillAnimation 3s ease-in-out infinite;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-text {
  font-size: 16px;
  color: white;
  font-weight: bold;
}

.kvkk-consent {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  margin-left: -10px;
  text-align: left;
  margin-top: 10px;
}

.kvkk-consent input[type="checkbox"] {
  margin-right: -25px;
  margin-top: 4px;
}

.kvkk-consent label {
  font-size: 14px;
  line-height: 1.4;
}

@keyframes fillAnimation {
  0% {
    width: 70%;
  }
  50% {
    width: 85%;
  }
  100% {
    width: 80%;
  }
}

.loader-container {
  text-align: center;
  margin-top: 50px;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-message {
  font-size: 18px;
  font-weight: 500;
  color: #3498db;
  margin-top: 20px;
  padding: 0 20px;
}

.contact-form {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form input {
  width: 80%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-form button {
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.contact-form button:hover {
  background-color: #2980b9;
}

.submission-message {
  font-size: 18px;
  color: #27ae60;
  margin-top: 20px;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 500px;
  width: 100%;
  position: relative;
}

.modal-content h2 {
  font-size: 24px;
  color: #2c3e50;
  margin-bottom: 15px;
}

.modal-content p {
  font-size: 18px;
  color: #e74c3c;
}

.modal-content button {
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.modal-content button:hover {
  background-color: #2980b9;
}
