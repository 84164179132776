.quiz-container {
  text-align: center;
  padding: 20px;
  max-width: 700px;
  margin: 20px auto;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  margin-top: 45px;
  padding: 20px !important;
}

.quiz-container h2 {
  font-size: 22px;
  margin-bottom: 25px;
  color: #3498db;
}

.quiz-container button {
  display: block;
  width: 100%;
  margin-bottom: 12px;
  padding: 15px;
  background: #3498db;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  text-align: left;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.quiz-container button:hover {
  background: #2980b9;
  transform: translateY(-2px);
}

.gender-selection {
  display: flex;
  justify-content: space-between;
  margin: -25px -12.5px 25px;
  flex-wrap: nowrap;
}

.gender-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: calc(50% - 12.5px);
  margin: 0 6.25px;
}

.gender-option img {
  width: 100%;
  max-width: 150px;
  height: auto;
  border-radius: 12px;
  margin-bottom: 10px;
  object-fit: cover !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gender-option button {
  width: 100%;
  max-width: 150px;
  padding: 10px;
  font-size: 16px;
  text-align: center;
}

/* Media Queries */
@media (max-width: 600px) {
  .quiz-container {
    padding: 15px;
  }

  .quiz-container h2 {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .quiz-container button {
    font-size: 16px;
    padding: 12px;
  }

  .gender-selection {
    margin: -15px -7.5px 15px;
  }

  .gender-option {
    width: calc(50% - 7.5px);
    margin: 0 3.75px;
  }

  .gender-option img {
    max-width: 120px;
  }

  .gender-option button {
    font-size: 14px;
    padding: 8px;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .quiz-container {
    max-width: 600px;
  }
}

@media (min-width: 901px) {
  .quiz-container {
    max-width: 700px;
  }
}
